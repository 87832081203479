<template>
  <div id="body">
    <div class="fatherBox">
      <div class="headerBox">
        <p>班级列表</p>
        <el-button type="primary" class="btn" @click="AddClass">+ 创建班级</el-button>
      </div>
      <div class="query">
        <div class="queryLeft">
          <div style="float: left ;width: fit-content;" v-if=" Status == 'school' ? true :false">
            年级：
            <el-select v-model="gradeid" @change="classSelect()" clearable style="width: 150px;">
              <el-option v-for="(item,index) in cgradeList" :key="index" :label="item.TypeName" :value="item.id">
              </el-option>
            </el-select>
          </div>&nbsp;&nbsp;
          <div style="float: left;width: fit-content;" v-if=" Status == 'school' ? true :false">
            班级：
            <el-select v-model="classid" clearable style="width: 150px;">
              <el-option v-for="(item,index) in classList" :key="index" :label="item.ClassName" :value="item.id">
              </el-option>
            </el-select>
          </div>&nbsp;&nbsp;
        </div>
        <div class="queryRight">
          <el-button @click="reset()">重置</el-button>
          <el-button type="primary" @click="studentSearchfirst(1)">查询</el-button>
        </div>
      </div>

      <el-table :data="ClassInfos" v-loading="loading">
        <el-table-column prop="ID" label="班级编号" align="center" width="150"></el-table-column>
        <el-table-column v-if="typeId == '1'" prop="remark" align="center" label="班级备注" width="150"></el-table-column>
        <el-table-column prop="TeaName" label="班级" align="center">
          <!-- <el-table-column  prop="TeaName" label="现任班主任" width="260"> -->
          <template slot-scope="scope">
            {{scope.row.TypeName}}{{scope.row.ClassName}}
          </template>
        </el-table-column>

        <!--        <el-table-column prop="TypeName" label="年级" align="center" ></el-table-column>
        <el-table-column prop="ClassName" label="班级" align="center"> </el-table-column> -->
        <!-- <el-table-column prop="ClassName" label="班级"  width="260"> </el-table-column> -->
        <!-- <el-table-column prop="ranking" label="校内排名" sortable></el-table-column> -->
        <!-- <el-table-column prop="ranking" label="校内排名" sortable width="260"></el-table-column> -->
        <el-table-column prop="ClassNum" label="注册学生数" align="center"></el-table-column>
        <el-table-column prop="authNum" label="实名人数" align="center"></el-table-column>
        <el-table-column prop="parentNum" label="认证家长数" align="center"></el-table-column>
        <!-- <el-table-column  prop="ClassNum" label="人数" width="360"></el-table-column> -->
        <el-table-column prop="TeaName" label="现任班主任" align="center">
          <!-- <el-table-column  prop="TeaName" label="现任班主任" width="260"> -->
          <template slot-scope="scope">
            <el-button v-if="scope.row.TeaName" type="text" size="small" @click="TeaNames(scope.$index, scope.row)">
              {{scope.row.TeaName}}
            </el-button>
            <span v-else>暂无</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" fixed="right" align="center" width="220">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" style="float: left;" plain v-if="typeId == 1" @click="changeName(scope.row)">修改班名
            </el-button>
            <el-button size="mini" type="primary" style="float: left;" plain v-else disabled >修改班名
            </el-button>
            <el-button size="mini" type="primary" plain v-if="scope.row.TeaName == null?true:false"
              @click="compile(scope.$index, scope.row)">编辑班主任</el-button>
            <el-button size="mini" type="primary" plain v-if="scope.row.TeaName == null?false:true"
              @click="Change(scope.$index, scope.row)">更换班主任</el-button>

            <el-button size="mini" type="primary" style="float: left; margin-left: 0px;" plain v-if="scope.row.is_open == 0" @click="changOpen(scope.row)">关闭申请
            </el-button>
            <el-button size="mini" type="primary" style="float: left; margin-left: 0px;" plain v-if="scope.row.is_open == 1" @click="changOpen(scope.row)">开启申请
            </el-button>

            <el-button size="mini" type="primary" v-if="scope.row.ClassNum==0" @click="classDel(scope.row)">&nbsp;&nbsp;&nbsp;删&nbsp;&nbsp;&nbsp;&nbsp;除&nbsp;&nbsp;&nbsp;
            </el-button>
            <el-button size="mini" type="primary" v-else disabled>&nbsp;&nbsp;&nbsp;删&nbsp;&nbsp;&nbsp;&nbsp;除&nbsp;&nbsp;&nbsp;</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block" v-show="ClassInfos.length > 0">
        <el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
          layout="total,prev, pager, next, jumper" :current-page="currpage" :total="CountNum" style="margin-top:20px">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="创建班级" :visible.sync="dialogFormVisible" top="20vh" :close-on-click-modal="false" width="400px">
      <el-form :model="create" ref="articleForm" label-position="right" label-width="100px">
        <el-form-item label="年级:">
          <el-select v-model="create.grade" clearable placeholder="年级" style="width:200px;float:left">
            <el-option v-for="item in gradeList" :key="item.ID" :label="item.TypeName" :value="item.ID">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="班级:">
          <el-select v-model="create.Class" clearable placeholder="班级" allow-create filterable
            style="width:200px;float:left" @change="ClassName">
            <el-option v-for="item in ClassLists" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注班级名:" v-if="typeId == '1'">
          <el-input v-model.trim="create.remark" placeholder="备注名称" type="text" style="width:200px;float:left" />
        </el-form-item>
        <el-form-item label="指定班主任:">
          <el-select v-model="create.ClassCharge" clearable placeholder="可不指定" style="width:200px;float:left">
            <el-option v-for="item in ChargeLists" :key="item.TeaUserID" :label="item.TeaName" :value="item.TeaUserID">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-button plain class="btn" style="margin-left:5px" @click="cancel()">取消</el-button>
      <el-button type="primary" class="btn" style="margin-left:10px" @click="Create()">创建</el-button>
    </el-dialog>

    <el-drawer title="教师列表" :visible.sync="Teadrawer" direction="rtl" size="23%">
      <el-input v-model.trim="searchTname" placeholder="教师名称" type="text" style="width:200px;" />
      <el-button type="primary" class="btn" style="margin-left:10px" @click="searchTnamef()">搜索</el-button>
      <el-table :data="ChargeListAll">
        <el-table-column property="TeaName" width="100">
          <template slot-scope="scope" >
            <el-image style="width: 60px; height: 60px" :src="scope.row.HeadImageUrl" fit="contain"></el-image>
          </template>
        </el-table-column>
        <el-table-column property="TeaName" width="150">
          <template slot-scope="scope" >
            {{scope.row.remark_name}}
            <br>
            {{scope.row.Mobile}}
            <br>
            <span v-if="scope.row.ClassName!=null" style="color: red;">该教师为{{scope.row.TypeName + scope.row.ClassName + scope.row.typename}}</span>
          </template>
        </el-table-column>
        <el-table-column label="">
          <template slot-scope="scope" v-if="scope.row.ClassName==null?true:false">
            <el-button size="mini" type="primary" @click="choice(scope.$index, scope.row)">选择</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-drawer>

    <el-dialog title="选择教师" :visible.sync="Teadialog" top="30px">
      <span>是否指定<p style="color:#1890FF;display:inline">{{TeaName}}</p> 成为 <p style="color:#1890FF;display:inline">
          {{ClassNames}}
        </p> 的班主任?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="Teadialog = false">取 消</el-button>
        <el-button type="primary" @click="affirm">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="修改班级备注名" :visible.sync="dialogFormVisiblename" top="20vh" :close-on-click-modal="false"
      width="400px">
      <el-form ref="articleForm" label-position="right" label-width="100px">
        <el-form-item label="备注班级名:">
          <el-input v-model.trim="remark" placeholder="备注名称" type="text" style="width:200px;float:left" />
        </el-form-item>
      </el-form>
      <el-button type="primary" class="btn" style="margin-left:10px" @click="changeNameOk()">确定</el-button>
    </el-dialog>

  </div>
</template>

<script>
  // import Json from '../../../mock.js'
  import {
    SchoolClassList,
    SchoolclassAdd,
    classTypeInfo,
    teacherList,
    editClass,
    classDel,
    classOpen,
    classRemark
  } from '@/api/user.js'
  import {
    gradeClass
  } from '@/api/TeacherApi.js';
  import {
    mapGetters
  } from "vuex";
  export default {
    computed: {
      ...mapGetters(["Status"]),
    },
    data() {
      return {
        typeId: JSON.parse(localStorage.getItem('userInfo')).Type, //学校类型（1代表幼儿园 2小学 3初中 4高中）
        create: {
          grade: '', // 年级
          Class: '', // 班级
          ClassCharge: '' // 班主任
        },
        ClassInfos: [],
        pagesize: 10, // 每页显示条目个数
        currpage: 1, // 初始页
        CountNum: 0, // 总条数
        loading: false, //加载框
        dialogFormVisible: false, // 弹窗
        dialogFormVisiblename: false,
        gradeList: [], // 年级选项
        ChargeLists: [], // 班主任列表
        ChargeListAll: [], // 班主任所有列表
        Classtitle: '', // 班级名字
        TeaName: '', // 教师名
        ClassNames: '', // 班级名
        Teadrawer: false, // 抽屉
        Teadialog: false, // 选择教师弹窗
        ClassType: '', // 年级ID
        ClassID: '', // 班级ID
        ClassNamies: '', // 班级名称
        createble: '', // 创建的条目
        serchList: {}, //查询条件
        ClassLists: this.$util.data.ClassLists, // 班级选项
        ttype: 0, // 编辑班主任 给1  更换班主任给2
        gName: 0, // 年级名称
        cName: 0, // 班级名称
        oldteacher: '', // 更换班主任是 获取原来的班主任名称
        gradeid: '', //年级查询id
        cgradeList: [], //年级数组
        classid: '', //班级查询id
        classList: [], // 班级数组
        gradeidsearch: '',
        classidsearch: '',
        remark: '', //修改班级备注名
        classObj: {} ,// 班级对象
        searchTname: '' ,// 搜索教师名称
      }
    },
    mounted() {
      this.ClassList({}, 1) // 获取班级列表
      this.classTypeInfo()  // 获取年级信息
      this.teacherList()    // 获取教师列表
      this.gradeListvue()   // 获取年级班级信息
    },
    methods: {
      reset() { //重置按钮
        this.classList = [];
        this.gradeid = '';
        this.classid = '';
        this.gradeidsearch = '';
        this.classidsearch = '';
        this.ClassList({}, 1) //获取班级列表
      },
      studentSearchfirst(val) { //只有当点击查询时候走这个方法

        this.gradeidsearch = this.gradeid;
        this.classidsearch = this.classid;
        this.currpage = 0;

        const data = {
          page: val,
          limit: this.pagesize,
          gid: this.gradeidsearch,
          cid: this.classidsearch,
        }

        SchoolClassList(data).then(res => {
          this.currpage = 1;
          this.ClassInfos = res.data.List;
          this.CountNum = parseInt(res.data.CountNum);
          this.loading = false;
        }).catch(err => {
          console.log(err)
        })
      },
      compile(index, row) { // 编辑班主任
        this.ClassNamies = row.ClassName // 班级名称赋值
        this.ClassType = row.ClassType // 年级ID赋值
        this.ClassID = row.ID // 班级ID赋值
        this.ClassNames = row.TypeName + row.ClassName // 年级班级赋值
        this.gName = row.TypeName // 年级班级赋值
        this.cName = row.ClassName // 班级赋值
        this.ttype = 1
        this.Teadrawer = true

        const data = {
          page: 1,
          limit: 300,
          search: this.searchTname, // 搜索
        }
        teacherList(data).then(res => {
          if (res.status.code == 1) {
            this.ChargeListAll  = res.data.List
          }
        }).catch(err => {
          console.log(err)
        })

      },
      Change(index, row) { // 更换班主任
        //console.log(row)
        this.Teadrawer = true
        this.ClassNamies = row.ClassName // 班级名称赋值
        this.ClassType = row.ClassType // 年级ID赋值
        this.ClassID = row.ID // 班级ID赋值
        this.ClassNames = row.TypeName + row.ClassName // 年级班级赋值
        this.gName = row.TypeName // 年级班级赋值
        this.cName = row.ClassName // 班级赋值
        this.oldteacher = row.TeaName // 班级赋值
        this.ttype = 2

        const data = {
          page: 1,
          limit: 300,
          search: this.searchTname, // 搜索
        }
        teacherList(data).then(res => {
          if (res.status.code == 1) {
            this.ChargeListAll  = res.data.List
          }
        }).catch(err => {
          console.log(err)
        })

      },
      choice(index, row) { // 点击选中的教师
        // console.log(row.TeaUserID, '老师ID')
        this.TeaUserID = row.TeaUserID // 教师ID赋值
        this.TeaName = row.TeaName // 教师名字赋值
        this.Teadialog = true
      },
      affirm() { // 选择教师的弹窗确认
        if (this.ttype == 1) {
          this.oldTeaName = '';
        } else {
          this.oldTeaName = this.oldteacher;
        }

        const data = {
          classID: this.ClassID, // 班级ID
          gradeID: this.ClassType, // 年级ID
          ClassName: this.cName, // 班级名称
          TypeName: this.gName, // 年级名称
          teacher: this.TeaUserID, // 班主任ID
          newteacher: this.TeaName, // 新班主任名称
          oldteacher: this.oldTeaName, // 新班主任名称
          type: this.ttype // 类型 1 新增 2更换
        }

        editClass(data).then(res => {
          if (res.status.code == 1) {
            this.$message({
              message: '指定成功',
              type: 'success'
            })
            this.Teadrawer = false
            this.Teadialog = false
            this.ClassList({}, 1) // 刷新
            this.teacherList()
          } else {
            this.$message.error(res.status.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      },
      ClassList(serchJson, currpage) { // 班级列表
        this.loading = true
        const data = serchJson //  查询条件
        data["page"] = currpage //	当前页数
        data["limit"] = this.pagesize // 每页条数
        data["gid"] = this.gradeidsearch //	当前年级
        data["cid"] = this.classidsearch // 每页班级

        SchoolClassList(data).then(res => {
          if (res.status.code == 1) {
            this.ClassInfos = res.data.List
            this.CountNum = parseInt(res.data.CountNum) // 总条数
            this.currpage = currpage // 分页显示当前页
            this.loading = false
          }
        }).catch(err => {
          console.log(err)
        })
      },
      AddClass() { // 创建班级
        this.dialogFormVisible = true
      },
      classTypeInfo() { // 年级信息
        classTypeInfo().then(res => {
          this.gradeList = res.data
        }).catch(err => {
          console.log(err)
        })
      },
      teacherList() { // 教师列表
        const data = {
          page: 1,
          limit: 300
        }
        teacherList(data).then(res => {
          this.ChargeLists    = res.data.List.filter(item => item.ClassName == null)
          this.ChargeListAll  = res.data.List
        }).catch(err => {
          console.log(err)
        })
      },
      cancel() { // 点击取消
        this.dialogFormVisible = false
      },
      ClassName(id) { // 监听班级
        this.Classtitle = this.ClassLists.find(item => item.value == id)
        this.createble = this.Classtitle == undefined ? id : this.Classtitle.label
        // console.log(this.createble)
      },
      Create() { // 点击创建
        const data = {
          classType: this.create.grade, // 年级ID
          remark: this.create.remark == undefined ? '' : this.create.remark, // 班级备注名称
          className: this.createble,
          teacher: this.create.ClassCharge // 班主任ID
        }

        SchoolclassAdd(data).then(res => {
          if (res.status.code == 1) {
            this.$message({
              message: '创建成功',
              type: 'success'
            })
            this.ClassList({}, 1) // 刷新
            this.gradeListvue()  // 获取年级班级信息
            this.dialogFormVisible = false
            this.create.grade = ''
            this.create.Class = ''
            this.create.ClassCharge = ''
            this.createble = ''
          } else {
            this.$message.error(res.status.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      },
      TeaNames(index, row) { // 点击跳转教师首页
        // console.log(row.ID)
        this.$router.push({
          name: 'teacherHome',
          params: {
            ClassID: row.ID
          }
        })
      },
      handleCurrentChange(val) { // 翻页
        this.ClassList({}, val)
        // this.currpage = val
      },
      gradeListvue() { // 年级班级列表
        const data = {
          type: 1 //没用
        }
        gradeClass(data).then(res => {
          this.cgradeList = res.data;
        }).catch(err => {
          console.log(err)
        })
      },
      classSelect() { // 班级列表
        this.classid = ''
        this.classList = []

        const data = {
          gid: this.gradeid
        }

        if (this.gradeid != "") {
          gradeClass(data).then(res => {
            this.classList = res.data;
          }).catch(err => {
            console.log(err)
          })
        }
      },
      changeName(row) { // 修改班级备注名
        this.dialogFormVisiblename = true
        this.classObj = row
      },
      changeNameOk() { // 修改班级备注名

        if (this.remark == "") {
          return false;
        }
        const data = {
          classid: this.classObj.ID,
          TypeName: this.classObj.TypeName,
          ClassName: this.classObj.ClassName,
          remark: this.remark
        }

        classRemark(data).then(res => {
          if (res.data == 1) {
            this.$message({
              message: '修改成功',
              type: 'success'
            })
            this.remark = '';
            this.ClassList({}, this.currpage) //获取班级列表
            this.dialogFormVisiblename = false;
          }
        }).catch(err => {
          console.log(err)
        })
      },
      changOpen(row) { // 班级开启关闭申请
        const data = {
          classid: row.ID, // 班级ID
          open: row.is_open == 0 ? 1 : 0 // 状态
        }
        classOpen(data).then(res => {
          if (res.status.code == 1) {
            this.$message({
              message: '操作成功',
              type: 'success'
            })
            this.ClassList({}, this.currpage) //获取班级列表
          } else {
            this.$message.error('操作失败')
          }
        }).catch(err => {
          console.log(err)
        })
      },
      classDel(row) { // 删除班级
        this.$confirm('此操作将删除班级, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const data = {
            classid: row.ID, // 班级ID
            TypeName: row.TypeName, // 年级名称
            ClassName: row.ClassName, // 班级名称
          }
          classDel(data).then(res => {
            if (res.status.code == 1) {
              this.$message({
                message: '移除成功',
                type: 'success'
              })
              if (this.ClassInfos.length == 1) {
                if (this.currpage > 1) {
                  this.ClassList({}, this.currpage - 1) //获取班级列表
                } else {
                  this.ClassList({}, this.currpage) //获取班级列表
                }
              } else {
                this.ClassList({}, this.currpage) //获取班级列表
              }
            } else {
              this.$message.error(res.status.msg)
            }
          }).catch(err => {
            console.log(err)
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      searchTnamef() { // 查询教师
          const data = {
            page: 1,
            limit: 300,
            search: this.searchTname, // 搜索
          }
          teacherList(data).then(res => {
            if (res.status.code == 1) {
              this.ChargeListAll  = res.data.List
            }
          }).catch(err => {
            console.log(err)
          })
      },

    }
  }
</script>

<style lang="scss" scoped>
  #body {
    // height: 100%;
    background: #f0f2f5;
  }

  .fatherBox {
    width: 98%;
    // height: 800px;
    display: flex;
    background: white;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;

    .headerBox {
      display: flex;
      height: 52px;
      width: 98%;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #e9e9e9;

      p {
        font-size: 16px;
        font-family: 萍方-简;
        font-weight: normal;
        color: #000000;
        margin-left: 32px;
      }

      .btn {
        display: flex;
        align-items: center;
        width: 109px;
        height: 32px;
        opacity: 1;
        border-radius: 2px;
      }
    }

    .query {
      width: 98%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      padding-left: 32px;

      .queryLeft {
        width: 70%;
        display: flex;

        div {
          margin-right: 20px;
          width: 35%;
          text-align: left;

          .input,
          .select {
            width: 60%;
          }
        }
      }
    }
  }
</style>
